
<template>
    <div>
        <CSDialog
            dialogTitle="线下付款"
            dialogWidth="1120px"
            :dialogVisible="modalShowStatus"
            @onConfirm="submitPayResult"
            @onClose="testClose"
            dialogConfirmBtnText="确定"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px; font-size: 24px;"
            >
                <input type="file" id="upload-file" style="display: none" />

                <div class="form-item">
                    <div class="form-item-label">已付金额</div>
                    <div class="form-item-content">
                        <table class="table modal-inner-table">
                            <thead>
                                <tr>
                                    <th class="text-center">类目</th>
                                    <th class="text-center">实际付款金额</th>
                                    <th class="text-center">支付渠道</th>
                                    <th class="text-center">付款凭证</th>
                                    <th class="text-center">操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(payInfo, index) in items"
                                    :key="index"
                                >
                                    <td class="text-center">
                                        {{ billDetail[index].name }}
                                    </td>
                                    <td class="text-center">
                                        {{ billDetail[index].paidAmount }} 元
                                    </td>
                                    <td class="text-center">
                                        <CSSelect height="40px" style="width: 180px;" i-width="36px">
                                        <select

                                            v-model="payInfo.channel"
                                        >
                                            <option value="">请选择</option>
                                            <option
                                                v-for="(
                                                    channel, key
                                                ) in payChannel"
                                                :key="key"
                                                :value="key"
                                            >
                                                {{ channel }}
                                            </option>
                                        </select>
                                        </CSSelect>
                                    </td>
                                    <td class="text-center">
                                        <span v-if="payInfo.archive" class="allow-click" @click="lookArchive(payInfo.archive)">
                                            查看
                                        </span>
                                        <template v-else>-</template>
                                    </td>
                                    <td class="text-center">
                                        <span class="allow-click" @click="uploadArchive(index)">
                                            上传付款凭证
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-item-label">备注</div>
                    <div class="form-item-content">
                        <textarea v-model="comment"
                            placeholder="已付金额与实际应付金额不一样，需填写原因，限50个字"
                        ></textarea>
                    </div>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
import { commitPaymentUrl, queryContractDetailUrl } from "@/requestUrl";
import { PAY_CHANNEL } from "../../../common/commonConst";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";

export default {
    name: "OfflinePayModal",
    props: {
        modalShowStatus: Boolean,
        billId: Number,
        contractId: Number,
        billDetail: Array,
    },
    components: {
        CSDialog,
        CSSelect
    },
    data() {
        return {
            payChannel: {
                [PAY_CHANNEL.CASH_PAY]: "现金支付",
                [PAY_CHANNEL.BANK_PAY]: "银行转帐",
                [PAY_CHANNEL.ALI_PAY]: "支付宝转账",
                [PAY_CHANNEL.WE_CHAT_PAY]: "微信转账",
            },
            comment: "",
            items: [],
            regionId: this.$vc.getCurrentRegion().code,
            operatorId: this.$vc.getCurrentStaffInfo().userId,
            constractDetail: {},
        };
    },
    watch: {
        modalShowStatus(val, oldVal) {
            if (val) {

                this.items = this.billDetail.map((val) => {
                    return {
                        id: val.id,
                        channel: "",
                        archive: "",
                    };
                });
            }
        },
    },
    mounted() {
        document.body.addEventListener("click", () => {
            // this.changeModalShowStatus(false);
        });

    },
    methods: {
        testClose () {
            this.changeModalShowStatus(false);
        },
        lookArchive(imgSrc) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                title: '查看付款凭证',
                photos: [imgSrc],
            });
        },
        /**
         * 上传凭证
         * @param {Number} index
         *  */
        uploadArchive(index) {
            let event;
            const el = document.querySelector("#upload-file");
            el.addEventListener(
                "change",
                async (e) => {
                    const photo = await this.choosePhoto(e);
                    this.$set(this.items[index], "archive", photo);
                },
                { once: true }
            );
            el.click();
        },
        /**
         * 上传图片
         * @param {String} base64 base64格式
         *  */
        async uploadPhoto(base64) {
            return new Promise((reslove, reject) => {
                this.$vc.http.post(
                    "uploadImg",
                    "upload",
                    {
                        img: base64,
                    },
                    {
                        headres: {
                            "Content-Type": "application/json",
                        },
                    },
                    (resStr, res) => {
                        reslove(res.body.fileSaveName);
                    },
                    (errText, err) => {
                        reject(errText);
                    }
                );
            });
        },
        /**
         * 选择图片
         * @param {HTMLEvent} event
         *  */
        choosePhoto(event) {
            return new Promise((resolve) => {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    // 获取目前上传的文件
                    var file = photoFiles[0]; // 文件大小校验的动作
                    if (file.size > 1024 * 1024 * 1) {
                        this.$vc.toast("图片大小不能超过 2MB!");
                        return false;
                    }
                    var reader = new FileReader(); //新建FileReader对象
                    reader.readAsDataURL(file); //读取为base64
                    reader.onloadend = async (e) => {
                        const photo = await this.uploadPhoto(
                            reader.result
                        ).catch((err) => {
                            console.error("上传照片失败");
                            return "";
                        });
                        resolve(photo);
                    };
                }
            });
        },
        // 提交支付结果
        submitPayResult() {
            const { regionId, operatorId, billId, items, comment } = this;
            this.$fly
                .post(commitPaymentUrl, {
                    regionId,
                    operatorId,
                    billId,
                    items,
                    comment,
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.$vc.toast('保存成功');
                        this.changeModalShowStatus(false);
                    } else {
                        this.$vc.toast(res.msg);
                    }
                });
        },
        /**
         * 隐藏/显示弹窗
         * @param {Boolean} isShow 是否显示
         *  */
        changeModalShowStatus(isShow) {
            this.$emit("change-modal-status", isShow);
        },
    },
};
</script>

<style lang="stylus" scoped>
@import './offlinePayModal.styl'
</style>
