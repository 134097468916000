<template>
    <div>
        <div id="component">
            <div class="filter-panel">
                <CSSelect style="margin-right: 0; width: 185px;">
                    <el-date-picker
                        v-model="queryParams.startDate"
                        style="width: 157px;"
                        format="yyyy-MM-dd"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择开始时间"
                        prefix-icon="el-icon-time"
                        :editable="false"
                    >
                    </el-date-picker>
                </CSSelect>
                <span class="inline-gray"></span>
                <CSSelect style=" width: 185px;">
                    <el-date-picker
                        v-model="queryParams.endDate"
                        style="width: 157px;"
                        format="yyyy-MM-dd"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="请选择结束时间"
                        prefix-icon="el-icon-time"
                        :editable="false"
                    >
                    </el-date-picker>
                </CSSelect>
                <input
                    type="text"
                    class="cs-input"
                    placeholder="搜索合同编号/租客/房间"
                    v-model="queryParams.keyword"
                    style="width: 220px"
                />
                <CSSelect>
                    <select
                        class="states_"
                        v-model="queryParams.state"
                        :style="{
                            color: queryParams.state != '' ? '#000' : '#999',
                        }"
                    >
                        <option value="">
                            账单状态不限
                        </option>
                        <option
                            v-for="(val, key) in billState"
                            :key="key"
                            :value="key"
                            v-html="val"
                        >
                        </option>
                    </select>
                </CSSelect>
                <button
                    type="button"
                    class="btn btn-primary"
                    style="margin-left: 10px; margin-bottom: 15px"
                    @click="renderPagination()"
                >
                    查询
                </button>
                <div style="padding-bottom: 15px">
                    <span style="vertical-align: middle; color: #444"
                        >按支付日期提前</span
                    >
                    <CSSelect
                        style="
                            width: 140px;
                            vertical-align: middle;
                            margin: 0 20px;
                        "
                    >
                        <select
                            v-model="countdownDays"
                            style="vertical-align: middle"
                        >
                            <option :value="0">请选择</option>
                            <option v-for="n in 14" :key="n" :value="n">
                                {{ n }}
                            </option>
                        </select>
                    </CSSelect>
                    <span style="vertical-align: middle; color: #444"
                        >天提醒财务，通知企业缴费</span
                    >
                    <button
                        class="btn btn-primary"
                        style="
                            margin-left: 20px;
                            vertical-align: middle;
                            margin-bottom: 0;
                        "
                        @click="setBillNotifyConfig"
                    >
                        保存
                    </button>
                </div>
            </div>

            <div class="result-panel">
                <CSTable :thead-top="filterHeight">
                    <template v-slot:thead>
                        <tr>
                            <th>账单生成时间</th>
                            <th>账单状态</th>
                            <th>合同编号</th>
                            <th>租客</th>
                            <th>房间</th>
                            <th>实际应付金额</th>
                            <th>备注</th>
                            <th>操作</th>
                        </tr>
                    </template>
                    <template v-slot:tbody>
                        <tr v-for="bill in billList" :key="bill.id">
                            <td>
                                {{ bill.paymentDate }}
                            </td>
                            <td>
                                <el-badge
                                    is-dot
                                    class="item"
                                    :hidden="!bill.redDot"
                                    v-html="billState[bill.state]"
                                >

                                </el-badge>
                            </td>
                            <td>
                                <span
                                    class="allow-click"
                                    @click="lookContractDetail(bill.contractId)"
                                >
                                    {{ bill.contractNo }}
                                </span>
                            </td>
                            <td>
                                <div>
                                    <p style="margin-bottom: 0">
                                        {{ bill.lesseeCompany }}
                                    </p>
                                    <p style="margin-bottom: 0">
                                        {{ bill.lesseeName }}【{{
                                            bill.lesseePhone
                                        }}】
                                    </p>
                                </div>
                            </td>
                            <td>
                                <template
                                    v-if="bill.rooms && bill.rooms.length > 0"
                                >
                                    <div v-for="(item, index) in bill.rooms" :key="index">
                                        <p style="margin-bottom: 0">
                                            {{ item.buildingName + item.roomName }}
                                        </p>
                                    </div>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <span
                                    class="allow-click"
                                    @click="
                                        lookBillPayDeatil(
                                            bill.id,
                                            bill.accountsPayable
                                        )
                                    "
                                >
                                    {{ bill.accountsPayable }}元
                                </span>
                            </td>
                            <td>
                                <template
                                    v-if="bill.comment && bill.comment != ''"
                                >
                                    <span class="allow-click" @click="lookComment(bill.comment)">
                                        查看
                                    </span>
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td>
                                <div class="btn-group">
                                    <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        :disabled = "![   
                                                    BillState.UNPAID_NOTIFY,
                                                    BillState.UNPAID,
                                                ].includes(bill.state)"
                                        :class="[[   
                                                    BillState.UNPAID_NOTIFY,
                                                    BillState.UNPAID,
                                                ].includes(bill.state)?'':'disClss']"
                                    >
                                        操作
                                    </button>

                                    <ul class="dropdown-menu">
                                        <template
                                            v-if="
                                                [   
                                                    BillState.UNPAID_NOTIFY,
                                                    BillState.UNPAID,
                                                ].includes(bill.state)
                                            "
                                        >
                                            <template
                                                v-if="
                                                    bill.state ==
                                                    BillState.UNPAID
                                                "
                                            >
                                                <li>
                                                    <a
                                                        @click="
                                                            notifyPayment(
                                                                bill.id
                                                            )
                                                        "
                                                        style="width: 100%;"
                                                    >通知缴费</a
                                                    >
                                                </li>
                                            </template>
                                            <!-- <li><a href="#">变更账单</a></li> -->
                                            <li>
                                                <a
                                                    @click="
                                                        offlinePay(
                                                            bill.id,
                                                            bill.contractId
                                                        )
                                                    "
                                                    style="width: 100%;"
                                                >线下付款</a
                                                >
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                </CSTable>

                <!-- 分页 -->
                <Pagination
                    name="pagination"
                    componentName="Pagination"
                ></Pagination>
            </div>

            <ViewModal></ViewModal>
            <OfflinePayContract
                :modal-show-status="offlinePaymodal.visible"
                :bill-id="offlinePaymodal.billId"
                :contract-id="offlinePaymodal.contractId"
                :bill-detail="offlinePaymodal.billDetail"
                @change-modal-status="changeOfflinePyModal"
            ></OfflinePayContract>
            <!-- 合同详情 -->
            <el-drawer
                title=""
                size="800px"
                :visible.sync="contractDetailModal.visible"
                direction="rtl"
            >
                <ContractPreview
                    style="height: 100%"
                    :contract-info="contractDetailModal.contractDeatil"
                ></ContractPreview>
            </el-drawer>
            <ViewImageModal></ViewImageModal>

            <CSDialog
                dialogTitle="提示"
                dialogWidth="480px" dialog-confirm-btn-text="确定"
                :dialogVisible="commonModal.visible"
                @onClose="commonModal.visible = false"
                @onConfirm="commonModal.onOk"
            >
                <div
                    slot="dialog-content"
                    style="padding: 20px 30px; font-size: 24px; text-align: center;"
                >
                    {{ commonModal.title }}
                </div>
            </CSDialog>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";
import OfflinePayContract from "@/components/offlinePayModal/OfflinePayContract";
import ViewModal from "@/components/ViewModal.vue";
import ContractPreview from "@/components/ContractPreview.vue";
import ViewImageModal from "@/components/ViewImageModal.vue";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

import dayjs from "dayjs";
import {
    setBillNotifyConfigUrl,
    queryContractDetailUrl,
    queryBillDetailUrl,
    queryBillNotifyConfigUrl,
    queryBillListUrl,
    sendBillPaymentNotifyUrl,
} from "@/requestUrl";
import { BillState, PAY_CHANNEL } from "../../../common/commonConst";
import CSTable from "@/components/common/CSTable";

export default {
    props: {},
    components: {
        CSTable,
        CSSelect,
        Pagination,
        OfflinePayContract,
        ViewModal,
        ContractPreview,
        ViewImageModal,
        CSDialog,
    },
    data() {
        return {
            filterHeight: 0,
            commonModal: {
                visible: false,
                title: "",
                onCancel: () => {
                    this.commonModal.visible = false;
                },
                onOk: () => {},
            },
            contractDetailModal: {
                visible: false,
                contractId: null,
                contractDeatil: null,
            },
            offlinePaymodal: {
                billId: null,
                visible: false,
                contractId: null,
                billDetail: null,
            },
            countdownDays: 0,
            regionId: this.$vc.getCurrentRegion().communityId,
            currentPage:1,
            pageSize:10,
            billList: [],
            operatorId: this.$vc.getCurrentStaffInfo().userId,
            BillState,
            billState: {
                [BillState.UNPAID]: "待缴费<span style='display:inline-block;'>（未通知）</span>",
                [BillState.UNPAID_NOTIFY]: "待缴费<span style='display:inline-block;'>（已通知）</span>",
                [BillState.PAID]: "已缴费",
                [BillState.INVALIDITY]: "已作废",
            },
            queryParams: {
                startDate: dayjs().add(-30, "day").format("YYYY-MM-DD"),
                endDate: dayjs().format("YYYY-MM-DD"),
                // startDate: new Date(
                //   dayjs(
                //     dayjs()
                //       .subtract(30, "day")
                //       .format("YYYY-MM-DD") + "00:00:00"
                //   ).valueOf()
                // ),
                // endDate: new Date(
                //   dayjs(dayjs().format("YYYY-MM-DD") + "23:59:59").valueOf()
                // ),
                keyword: "",
                state: "",
                pageNo: 1,
                pageSize: 10,
            },
        };
    },

    mounted() {
        this.renderPagination();
        this.$vc.on(this.$route.path, "billManage", "refresh", () => {
            this.renderPagination();
        });
        this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
            this.renderPagination(pageNo);
        });
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    created() {
        window.addEventListener("keydown",this.renderPaginationDown);

        this.renderPagination();
        this.getBillNotifyConfig();
    },
    beforeDestroy(){
        window.removeEventListener("keydown",this.renderPaginationDown);
    },
    deactivated(){
         window.removeEventListener("keydown",this.renderPaginationDown);
    },
    methods: {
        renderPaginationDown(e){
            if(e.keyCode == 13){
                this.renderPagination();
            }
        },
        /**
         * 查看备注信息
         * @param {String} comment 备注信息
         *
         */
        lookComment(comment) {
            this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                title: "备注",
                content: comment,
            });
        },
        // 查询合同详情
        getContractDetail(contractId) {
            return new Promise((resolve) => {
                this.$fly
                    .post(queryContractDetailUrl, {
                        contractId: contractId,
                        operatorId: this.$vc.getCurrentStaffInfo().userId,
                    })
                    .then((res) => {
                        this.$set(
                            this.contractDetailModal,
                            "contractDeatil",
                            res.data
                        );
                        resolve(res);
                    });
            });
        },
        /**
         * 查看合同详情
         * @param {Number} contractId 合同id
         *  */
        async lookContractDetail(contractId) {
            const contractDetailRes = await this.getContractDetail(contractId);
            if (contractDetailRes.code != 0) {
                return;
            }
            this.contractDetailModal = {
                ...this.contractDetailModal,
                visible: true,
                contractId,
            };
        },
        /**
         * 线下支付结果
         * @param {Number} billId 账单id
         * @param {Number} contractId 合同id
         *  */
        async offlinePay(billId, contractId) {
            const billDetail = await this.queryBillPayDetailRequest(billId);
            this.offlinePaymodal = {
                visible: true,
                billId,
                contractId,
                billDetail,
            };
        },
        /**
         * 查看账单详情
         * @param {Number} billId 账单id
         *  */
        queryBillPayDetailRequest(billId) {
            return new Promise((resolve) => {
                this.$fly
                    .post(queryBillDetailUrl, {
                        regionId: this.regionId,
                        operatorId: this.operatorId,
                        billId,
                    })
                    .then((res) => {
                        resolve(res.data);
                    });
            });
        },
        /**
         * 显示查看账单详情弹窗
         * @param {Number} billId 账单id
         * @param {Number} payAmount 实际应付金额
         *  */
        async lookBillPayDeatil(billId, payAmount) {
            this.$router.push({name: 'contractBillDetail', params: {id: billId, payAmount}});
        },
        /**
         * 通知缴费
         * @param {Number} billId 账单id
         *  */
        notifyPayment(billId) {
          this.$CSDialog.confirm({
            title: '提示',
            message: "确定通知缴费?",
            onConfirm: () => {
              this.$fly
                .post(sendBillPaymentNotifyUrl, {
                  regionId: this.regionId,
                  operatorId: this.operatorId,
                  billId,
                })
                .then((res) => {
                  if (res.code != 0) {
                      return;
                  }
                  this.$CSDialog.instance.closeDialog();
                  this.renderPagination();
                  this.$vc.toast("发送成功");
                });
            }
          })
        },
        // 获取账单列表
        // getBillList() {
        //     this.$fly
        //         .post(queryBillListUrl, {
        //             regionId: this.regionId,
        //             operatorId: this.operatorId,
        //             ...this.queryParams,
        //         })
        //         .then((res) => {
        //             if (res.code != 0) {
        //                 return;
        //             }
        //             this.billList = res.data;
        //         });
        // },
        renderPagination(pageNo = 1,pageSize = 10){
            this.$fly
                .post(queryBillListUrl,{
                    regionId:this.regionId,
                    operatorId:this.operatorId,
                    ...this.queryParams,
                    pageNo,
                    pageSize,
                })
                .then((res)=>{
                    if(res.code != 0){
                        return;
                    }
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path, "pagination","init",{
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize
                        })
                    }
                    this.billList = res.data.datas
                })
        },
        // 设置账单通知时间
        setBillNotifyConfig() {
            this.$fly
                .post(setBillNotifyConfigUrl, {
                    regionId: this.regionId,
                    countdownDays: this.countdownDays,
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    this.$vc.toast("设置成功");
                });
        },
        // 获取账单通知时间配置
        getBillNotifyConfig() {
            this.$fly
                .get(`${queryBillNotifyConfigUrl}?regionId=${this.regionId}`)
                .then((res) => {
                    this.countdownDays = res.data;
                });
        },
        changeOfflinePyModal(isShow) {
            this.offlinePaymodal.visible = isShow;
        },
    },
};
</script>
<style scoped>
.el-badge .is-dot {
    width: 14px;
    height: 14px;
    background: #ff0000;
}
.back_ .ibox-content {
    padding: 0 !important;
}
.cs-btn-close {
    border-radius: 6px !important;
    box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3) !important;
    margin: 30px auto !important;
}
.cancel {
    width: 80px;
    height: 40px;
    background: #ff9f00;
    box-shadow: 0px 5px 10px 0px rgba(255, 159, 0, 0.3);
    border-radius: 6px;
    color: #ffffff;
    border: 0;
    font-size: 24px;
    margin-right: 30px;
}
.confirm {
    width: 80px;
    height: 40px;
    background: #1ab394;
    box-shadow: 0px 5px 10px 0px rgba(0, 182, 148, 0.3);
    border-radius: 6px;
    color: #ffffff;
    border: 0;
    font-size: 24px;
}
#component .el-dialog__footer {
    text-align: center;
}
#component .el-dialog__body {
    text-align: center;
}
.states_ {
    font-size: 14px;
    width: 220px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
    padding-left: 5px;
}
.icon_down {
    position: relative;
    top: 0;
    left: 210px;
}
input::-webkit-input-placeholder {
    color: #999;
}
input::-moz-placeholder {
    color: #999;
}
input::-moz-placeholder {
    color: #999;
}
input::-ms-input-placeholder {
    color: #999;
}
.disClss {
  background: #CCCCCC;
  border: none;
}
</style>
